var exports = {};
// Generated by CoffeeScript 1.10.0
(function () {
  var QJ, rreturn, rtrim;

  QJ = function (selector) {
    if (QJ.isDOMElement(selector)) {
      return selector;
    }

    return document.querySelectorAll(selector);
  };

  QJ.isDOMElement = function (el) {
    return el && el.nodeName != null;
  };

  rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;

  QJ.trim = function (text) {
    if (text === null) {
      return "";
    } else {
      return (text + "").replace(rtrim, "");
    }
  };

  rreturn = /\r/g;

  QJ.val = function (el, val) {
    var ret;

    if (arguments.length > 1) {
      return el.value = val;
    } else {
      ret = el.value;

      if (typeof ret === "string") {
        return ret.replace(rreturn, "");
      } else {
        if (ret === null) {
          return "";
        } else {
          return ret;
        }
      }
    }
  };

  QJ.preventDefault = function (eventObject) {
    if (typeof eventObject.preventDefault === "function") {
      eventObject.preventDefault();
      return;
    }

    eventObject.returnValue = false;
    return false;
  };

  QJ.normalizeEvent = function (e) {
    var original;
    original = e;
    e = {
      which: original.which != null ? original.which : void 0,
      target: original.target || original.srcElement,
      preventDefault: function () {
        return QJ.preventDefault(original);
      },
      originalEvent: original,
      data: original.data || original.detail
    };

    if (e.which == null) {
      e.which = original.charCode != null ? original.charCode : original.keyCode;
    }

    return e;
  };

  QJ.on = function (element, eventName, callback) {
    var el, i, j, len, len1, multEventName, originalCallback, ref;

    if (element.length) {
      for (i = 0, len = element.length; i < len; i++) {
        el = element[i];
        QJ.on(el, eventName, callback);
      }

      return;
    }

    if (eventName.match(" ")) {
      ref = eventName.split(" ");

      for (j = 0, len1 = ref.length; j < len1; j++) {
        multEventName = ref[j];
        QJ.on(element, multEventName, callback);
      }

      return;
    }

    originalCallback = callback;

    callback = function (e) {
      e = QJ.normalizeEvent(e);
      return originalCallback(e);
    };

    if (element.addEventListener) {
      return element.addEventListener(eventName, callback, false);
    }

    if (element.attachEvent) {
      eventName = "on" + eventName;
      return element.attachEvent(eventName, callback);
    }

    element["on" + eventName] = callback;
  };

  QJ.addClass = function (el, className) {
    var e;

    if (el.length) {
      return function () {
        var i, len, results;
        results = [];

        for (i = 0, len = el.length; i < len; i++) {
          e = el[i];
          results.push(QJ.addClass(e, className));
        }

        return results;
      }();
    }

    if (el.classList) {
      return el.classList.add(className);
    } else {
      return el.className += " " + className;
    }
  };

  QJ.hasClass = function (el, className) {
    var e, hasClass, i, len;

    if (el.length) {
      hasClass = true;

      for (i = 0, len = el.length; i < len; i++) {
        e = el[i];
        hasClass = hasClass && QJ.hasClass(e, className);
      }

      return hasClass;
    }

    if (el.classList) {
      return el.classList.contains(className);
    } else {
      return new RegExp("(^| )" + className + "( |$)", "gi").test(el.className);
    }
  };

  QJ.removeClass = function (el, className) {
    var cls, e, i, len, ref, results;

    if (el.length) {
      return function () {
        var i, len, results;
        results = [];

        for (i = 0, len = el.length; i < len; i++) {
          e = el[i];
          results.push(QJ.removeClass(e, className));
        }

        return results;
      }();
    }

    if (el.classList) {
      ref = className.split(" ");
      results = [];

      for (i = 0, len = ref.length; i < len; i++) {
        cls = ref[i];
        results.push(el.classList.remove(cls));
      }

      return results;
    } else {
      return el.className = el.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
    }
  };

  QJ.toggleClass = function (el, className, bool) {
    var e;

    if (el.length) {
      return function () {
        var i, len, results;
        results = [];

        for (i = 0, len = el.length; i < len; i++) {
          e = el[i];
          results.push(QJ.toggleClass(e, className, bool));
        }

        return results;
      }();
    }

    if (bool) {
      if (!QJ.hasClass(el, className)) {
        return QJ.addClass(el, className);
      }
    } else {
      return QJ.removeClass(el, className);
    }
  };

  QJ.append = function (el, toAppend) {
    var e;

    if (el.length) {
      return function () {
        var i, len, results;
        results = [];

        for (i = 0, len = el.length; i < len; i++) {
          e = el[i];
          results.push(QJ.append(e, toAppend));
        }

        return results;
      }();
    }

    return el.insertAdjacentHTML("beforeend", toAppend);
  };

  QJ.find = function (el, selector) {
    if (el instanceof NodeList || el instanceof Array) {
      el = el[0];
    }

    return el.querySelectorAll(selector);
  };

  QJ.trigger = function (el, name, data) {
    var e, error, ev;

    try {
      ev = new CustomEvent(name, {
        detail: data
      });
    } catch (error) {
      e = error;
      ev = document.createEvent("CustomEvent");

      if (ev.initCustomEvent) {
        ev.initCustomEvent(name, true, true, data);
      } else {
        ev.initEvent(name, true, true, data);
      }
    }

    return el.dispatchEvent(ev);
  };

  exports = QJ;
}).call(exports);
export default exports;